import React, {useContext, useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {Loader, Text} from '@gravity-ui/uikit';
import {BasketHeaderControls, BasketItems, BasketSummary} from '@features/basket';
import {BasketEmpty} from '@features/basket/components/BasketEmpty/BasketEmpty';
import {BasketItemsOut} from '@features/basket/components/BasketItemsOut';
import {CApi as userGwApi} from '@services/api';
import {AppContext} from 'app/context';
import {useBasket} from '@features/basket/hooks/useBasket';
import {basketStorage} from 'app/basketStorage';

import './BasketPage.scss';

const b = block('basket-page');

export const BasketPage: React.FC = () => {
    return (
        <BasketContent />
    );
};

const BasketContent: React.FC = () => {
    const {state, emit} = useContext(AppContext);
    const {
        items,
        itemsOut,
        summary,
        allChecked,
        allCheckedItemsOut,
        selectedCount
    } = state.basket;

    const {
        handleItemQuantityChange,
        handleItemCheckChange,
        handleItemOutCheckChange,
        handleSelectAll,
        handleSelectAllItemsOut,
        handleDeleteSelected,
        handleDeleteItem,
        handleDeleteItemOut
    } = useBasket();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        userGwApi()
            .basket.basketList()
            .then((r) => {
                const basketData = r.data;
                console.log('Данные получены:', basketData);

                const savedBasket = basketStorage.get() || {items: []};

                // Преобразуем данные корзины в нужный формат
                const updatedItems = basketData.items.map((item: any) => {
                    // Находим сохранённый элемент, если он есть
                    const savedItem = savedBasket.items.find((saved) => saved.id === item.id);

                    // Если элемент сохранён, используем его состояние checked, иначе устанавливаем true по умолчанию
                    const isChecked = savedItem?.checked ?? true;

                    const amount = item.price * item.itemsCnt;
                    return {
                        ...item,
                        checked: isChecked,
                        amount: amount,
                        newPrice: undefined // Изначально нет новой цены
                    };
                });

                emit('updateBasket', {
                    basket: {
                        items: updatedItems
                    }
                });

                emit('updateCounter', {
                    counterName: 'basketItems',
                    value: updatedItems.length
                });
            })
            .catch((err) => {
                console.error('Ошибка:', err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        // Рассчитываем общее количество и сумму для отмеченных товаров
        const totalQuantity = items.reduce(
            (prev, curr) => (curr.checked ? prev + curr.itemsCnt : prev),
            0
        );
        const totalAmount = items.reduce(
            (prev, curr) => (curr.checked ? prev + curr.amount : prev),
            0
        );

        // Проверяем, все ли товары отмечены
        const allChecked = items.every((item) => item.checked);
        const allCheckedItemsOut = itemsOut.every((item) => item.checked);

        // Количество выбранных элементов
        const selectedCount = items.filter((item) => item.checked).length;

        emit('updateBasket', {
            basket: {
                summary: {totalAmount, totalQuantity},
                allChecked,
                allCheckedItemsOut,
                selectedCount
            }
        });
    }, [items, itemsOut]);

    /*Разделение на доступные и недоступные после обновление, для отображения разных текстов ошибки*/
    const allItems = [...items, ...itemsOut];

    const itemsUnavailableOccurred = allItems.some((item) => item.quantityChangedToZero);
    const itemsCntExceedsQuantityOccurred = allItems.some((item) => item.quantityDecreased);
    /*Разделение на доступные и недоступные после обновление, для отображения разных текстов ошибки*/

    if (isLoading) {
        return (
            <div className={b(null, 'loader')}>
                <Loader size="l" />
            </div>
        );
    }

    if (items.length === 0 && itemsOut.length === 0) {
        return (
            <div className={b()}>
                <Text variant={'header-2'}>Корзина</Text>
                <BasketEmpty />
            </div>
        );
    }

    return (
        <div className={b()}>
            <Text variant={'header-2'}>Корзина</Text>
            <BasketHeaderControls
                allChecked={allChecked}
                onSelectAll={handleSelectAll}
                selectedCount={selectedCount}
                onDeleteSelected={handleDeleteSelected}
            />
            <BasketItems
                items={items}
                onItemQuantityChange={handleItemQuantityChange}
                onItemCheckChange={handleItemCheckChange}
                onDeleteItem={handleDeleteItem}
            />
            <BasketSummary summary={summary} />

            {itemsOut.length > 0 && (
                <BasketItemsOut
                    items={itemsOut}
                    itemsUnavailableOccurred={itemsUnavailableOccurred}
                    itemsCntExceedsQuantityOccurred={itemsCntExceedsQuantityOccurred}
                    allChecked={allCheckedItemsOut}
                    onSelectAll={handleSelectAllItemsOut}
                    onItemCheckChange={handleItemOutCheckChange}
                    onDeleteItem={handleDeleteItemOut}
                />
            )}
        </div>
    );
};