import {AppState} from './context';
import {userFromToken, userStorage} from './user';
import {tokenStorage} from './token';
import {countersStorage} from './counters';
import {basketStorage} from 'app/basketStorage';

export type EventName =
    'login'
    | 'logout'
    | 'increment'
    | 'clear_cnt'
    | 'updateCounter'
    | 'toggleBrandSearch'
    | 'updateBasket';

export const createEmitter = (setState: React.Dispatch<React.SetStateAction<AppState>>) => {
    return (name: EventName, event?: object) => {
        switch (name) {
            case 'logout':
                emitLogout(setState, event as LogoutEvent);
                break;
            case 'login':
                emitLogin(setState, event as LoginEvent);
                break;
            case 'increment':
                emitCounter(setState, event as CounterEvent);
                break;
            case 'clear_cnt':
                emitCounterClear(setState, event as CounterEvent);
                break;
            case 'updateCounter':
                emitCounterUpdate(setState, event as UpdateCounterEvent);
                break;
            case 'toggleBrandSearch':
                setState((prevState) => ({
                    ...prevState,
                    isBrandSearchActive: (event as { active: boolean }).active
                }));
                break;
            case 'updateBasket':
                emitBasketUpdate(setState, event as UpdateBasketEvent);
                break;
        }
    };
};

interface LogoutEvent {
    redirectTo: string;
}

function emitLogout(setState: React.Dispatch<React.SetStateAction<AppState>>, event: LogoutEvent) {
    userStorage.clear();
    tokenStorage.clear();

    // Не обновляем тут state, так как перегружаем страницу
    window.location.href = event?.redirectTo || '/';
}

interface LoginEvent {
    token: string;
}

function emitLogin(setState: React.Dispatch<React.SetStateAction<AppState>>, event: LoginEvent) {
    userStorage.clear();
    tokenStorage.clear();
    countersStorage.clear();

    tokenStorage.set(event.token);
    userStorage.set(userFromToken(event.token));
    countersStorage.set({});

    setState((prevState: AppState) => ({
        ...prevState,
        ...{
            user: userStorage.get()!,
            token: tokenStorage.get()!,
            counters: countersStorage.get()!
        }
    }));
}

interface CounterEvent {
    name: string;
}

function emitCounter(
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    event: CounterEvent
) {
    setState((prevState: AppState) => {
        const counters = prevState.counters;
        if (!counters[event.name]) {
            counters[event.name] = 0;
        }
        counters[event.name]++;
        countersStorage.set(counters);

        return {
            ...prevState,
            ...{
                counters: counters
            }
        };
    });
}

function emitCounterClear(
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    event: CounterEvent
) {
    setState((prevState: AppState) => {
        const counters = prevState.counters;
        delete counters[event.name];
        countersStorage.set(counters);

        return {
            ...prevState,
            ...{
                counters: counters
            }
        };
    });
}

// Обновления счетчика иконки корзины
interface UpdateCounterEvent {
    counterName: string;
    value: number;
}

// Обработка события updateCounter
function emitCounterUpdate(
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    event: UpdateCounterEvent
) {
    setState((prevState: AppState) => {
        const counters = prevState.counters;
        counters[event.counterName] = event.value; // Обновляем значение счетчика
        countersStorage.set(counters);

        return {
            ...prevState,
            ...{
                counters: counters
            }
        };
    });
}

interface UpdateBasketEvent {
    basket: Partial<AppState['basket']>;
}

function emitBasketUpdate(
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    event: UpdateBasketEvent
) {
    setState((prevState: AppState) => {
        const updatedBasket = {
            ...prevState.basket,
            ...event.basket
        };

        // Если обновлены items, пересчитываем allChecked
        if (event.basket.items) {
            updatedBasket.allChecked = updatedBasket.items.length > 0 && updatedBasket.items.every(item => item.checked === true);
        }

        // Сохраняем обновленную корзину в хранилище
        basketStorage.set(updatedBasket);

        return {
            ...prevState,
            basket: updatedBasket
        };
    });
}