import React from 'react';
import block from 'bem-cn-lite';
import './ProposalBadges.scss';
import {OfficialDealerBadge, ReturnBadge} from './components';

const b = block('proposal-badges');

export interface ProposalBadgesProps {
    className?: string;
    isOfficialDealer?: boolean;
    isReturnPossible?: boolean;
}

export const ProposalBadges: React.FC<ProposalBadgesProps> = ({
    className,
    isOfficialDealer,
    isReturnPossible,
}) => {
    return (
        <div className={b(null, className)}>
            <ReturnBadge isReturnPossible={isReturnPossible} />
            <OfficialDealerBadge isOfficialDealer={isOfficialDealer} />
        </div>
    );
};