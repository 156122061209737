import React from 'react';
import block from 'bem-cn-lite';
import StickyBox from 'react-sticky-box';
import {TrashBin} from '@gravity-ui/icons';
import {Button} from '@gravity-ui/uikit';
import {
    BrandFilter,
    Option,
    RangeGroup,
} from './components';
import {
    FilterRangeModel,
    FiltersModel,
} from '@features/search/interfaces';


import './Filters.scss';

const b = block('filters');

const stickyElementsTopOffset = 147; // TODO Вынести в общие константы (дубль в SearchPage.tsx)

export interface FiltersProps {
    // className: string;
    filters: FiltersModel;
    onFiltersChange?: (filters: FiltersModel) => void;
}

export const Filters: React.FC<FiltersProps> = ({ filters, onFiltersChange }) => {
    function handle(filtersParam: FiltersModel) {
        if (onFiltersChange) onFiltersChange(filtersParam);
    }

    function clearFilters() {
        handle({
            brands: filters.brands.map((x) => {
                return { ...x, isSelected: false };
            }),
            priceRange: getEmptyRange(filters.priceRange),
            deliveryDayRange: getEmptyRange(filters.deliveryDayRange),
            ratingRange: getEmptyRange(filters.ratingRange),
            quantityRange: getEmptyRange(filters.quantityRange),
            isReturnPossible: false,
            isOfficialDealer: false,
            priceLevel: false,
        });
    }

    function getEmptyRange(range: FilterRangeModel) {
        return { ...range, from: undefined, to: undefined };
    }

    return (
        <StickyBox offsetTop={stickyElementsTopOffset} className={b('filter-sticky')}>
            <div className={b()}>
                <div className={b('filter-header')}>
                    <span className={b('filter-title')}>Фильтры</span>
                    <Button size="m" onClick={clearFilters}>
                        <TrashBin />
                    </Button>
                </div>
                <div className={b('filter-controls')}>
                    <BrandFilter
                        brands={filters.brands}
                        onBrandsChange={(brands) => handle({ ...filters, brands: brands })}
                    ></BrandFilter>
                    <RangeGroup
                        title="Цена, ₽"
                        range={filters.priceRange}
                        onRangeChange={(range) => handle({ ...filters, priceRange: range })}
                        isCurrency={true}
                    ></RangeGroup>
                    <RangeGroup
                        title="Срок доставки, дни"
                        range={filters.deliveryDayRange}
                        onRangeChange={(range) => handle({ ...filters, deliveryDayRange: range })}
                    ></RangeGroup>
                    <RangeGroup
                        title="Рейтинг, %"
                        range={filters.ratingRange}
                        onRangeChange={(range) => handle({ ...filters, ratingRange: range })}
                    ></RangeGroup>
                    <RangeGroup
                        title="В наличии, шт."
                        range={filters.quantityRange}
                        onRangeChange={(range) => handle({ ...filters, quantityRange: range })}
                    ></RangeGroup>
                    <div className={b('filter-options')}>
                        <Option
                            title="Возвратность"
                            value={filters.isReturnPossible}
                            onValueChange={(value) => handle({ ...filters, isReturnPossible: value })}
                        ></Option>
                        <Option
                            title="Офиц. дилер"
                            value={filters.isOfficialDealer}
                            onValueChange={(value) => handle({ ...filters, isOfficialDealer: value })}
                        ></Option>
                        <Option
                            title="Уровень цен"
                            value={filters.priceLevel}
                            disabled={true}
                            onValueChange={(value) => handle({ ...filters, priceLevel: value })}
                        ></Option>
                    </div>
                </div>
            </div>
        </StickyBox>
    );
};
