import React from 'react';
import block from 'bem-cn-lite';
import {Checkbox, Text} from '@gravity-ui/uikit';
import {BasketItemModel} from '@features/basket/interfaces';

import './BasketItemsOut.scss';
import {Items} from './Items';

const b = block('basket-items-out');

interface BasketItemsOutProps {
    items?: BasketItemModel[] | undefined;
    itemsUnavailableOccurred: boolean;
    itemsCntExceedsQuantityOccurred: boolean;
    allChecked: boolean;
    onSelectAll: (checked: boolean) => void;
    onItemCheckChange: (id: string, checked: boolean) => void;
    onDeleteItem: (id: string) => void;
}

export const BasketItemsOut: React.FC<BasketItemsOutProps> = ({
    items,
    itemsUnavailableOccurred,
    itemsCntExceedsQuantityOccurred,
    allChecked,
    onSelectAll,
    onItemCheckChange,
    onDeleteItem
}) => {
    return (
        <>
            <div className={b('header')}>
                <Text variant={'subheader-2'} color={'secondary'} className={b('header-text')}>
                    Эти товары закончились
                </Text>

                <Checkbox
                    size={'l'}
                    className={b('select-all')}
                    checked={allChecked}
                    onChange={(event) => onSelectAll(event.target.checked)}
                >
                    <Text variant={'body-2'}>Выбрать всё</Text>
                </Checkbox>
            </div>

            <Items
                items={items}
                itemsUnavailableOccurred={itemsUnavailableOccurred}
                itemsCntExceedsQuantityOccurred={itemsCntExceedsQuantityOccurred}
                onItemCheckChange={onItemCheckChange}
                onDeleteItem={onDeleteItem}
            />
        </>
    );
};
