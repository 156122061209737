import React from 'react';
import block from 'bem-cn-lite';
import './Proposal.scss';
import {DeliveryDate, DeliveryDays, Price, ProposalBadges, Quantity, Rating} from '@components';
import {BasketControls} from '../BasketControls';
import {ProposalModel} from '@features/search/interfaces';
import {useInView} from 'react-intersection-observer';

const b = block('proposal');

export interface ProposalProps {
    proposal: ProposalModel;
    addedItems: {[key: string]: boolean};
    quantities: {[key: string]: number};
    loadingAddedItems: {[key: string]: boolean};
    handleQuantityChange: (proposalKey: string, newQuantity: number) => void;
    handleAddToBasket: (proposalKey: string) => void;
    handleDeleteFromBasket: (proposalKey: string) => void;
}

export const Proposal: React.FC<ProposalProps> = ({
    proposal,
    addedItems,
    quantities,
    loadingAddedItems,
    handleQuantityChange,
    handleAddToBasket,
    handleDeleteFromBasket
}) => {
    const {ref} = useInView({
        onChange: (eInView, eEntry) => {
            console.log(`Item::useInView::onChange ${JSON.stringify({
                useInView: eInView,
                target: eEntry.target.id
            })}`);
            if (eInView && proposal.generation > 0)
                eEntry.target.classList.add('proposal__proposal_gen1');
        }
    });

    const quantity = quantities[proposal.key] || proposal.multiplicity;
    const isAdded = addedItems[proposal.key] || false;
    const isLoading = loadingAddedItems[proposal.key] || false;

    return (
        <div
            ref={ref}
            className={b('proposal')}
        >
            <ProposalBadges
                className={b('icons')}
                isOfficialDealer={proposal.isOfficialDealer}
                isReturnPossible={proposal.isReturnPossible}
            />
            <Rating className={b('rating')} value={proposal.rating} />
            <Quantity className={b('quantity')} value={proposal.quantity} />
            <DeliveryDays
                className={b('delivery-days')}
                value={proposal.deliveryDays}
            />
            <DeliveryDate
                className={b('delivery-date')}
                value={proposal.deliveryDate}
            />
            <Price className={b('price')} value={proposal.price} />

            <BasketControls
                value={quantity}
                multiplicity={proposal.multiplicity}
                maxQuantity={proposal.quantity}
                onQuantityChange={(newQuantity: number) =>
                    handleQuantityChange(proposal.key, newQuantity)
                }
                onAddToBasket={() => handleAddToBasket(proposal.key)}
                onDelete={() => handleDeleteFromBasket(proposal.key)}
                className={b('basket-controls')}
                isAdded={isAdded}
                loading={isLoading}
            />
        </div>
    );
};