import React, {useContext} from 'react';
import block from 'bem-cn-lite';
import {ToasterComponent, ToasterProvider} from '@gravity-ui/uikit';
import {Navigate, Outlet} from 'react-router-dom';

import './AuthWrapper.scss';
import {AppContext} from '../../app/context';

const className = block('auth-wrapper');

export const AuthWrapper: React.FC = () => {
    const context = useContext(AppContext);
    if (!context.state.token) {
        return <Navigate to="/login" />;
    }
    return (
        <ToasterProvider>
            <div className={className()}>
                <div className={className('container')}>
                    <Outlet />
                </div>
            </div>
            <ToasterComponent />
        </ToasterProvider>
    );
};
