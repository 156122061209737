import { useMemo } from "react";
import { sortItems } from "../utils/sortUtils";
import { useBasket } from "./useBasket";

export const useSortedItems = (
    sortOption: {property: string; direction: 'asc' | 'desc'} | null,
    sortPriority: string[],
    defaultSortPriority: string[],
    defaultDirection: Map<string, number>
) => {
    const {items} = useBasket();

    const mappedItems = useMemo(() => {
        return items?.map((item) => ({
            ...item,
            isReturnPossible: item.returnCondition !== 'impossible',
        }));
    }, [items]);

    const sortedItems = useMemo(() => {
        return sortItems(mappedItems, sortOption, sortPriority, defaultSortPriority, defaultDirection);
    }, [mappedItems, sortOption, sortPriority, defaultSortPriority, defaultDirection]);

    return sortedItems;
};