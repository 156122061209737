import React, {useContext, useState} from 'react';
import block from 'bem-cn-lite';
import './Item.scss';
import {ProposalModel} from '@features/search/interfaces';
import {ArticleInfo, ArticleInfoModel} from '@components';
import {CApi as userGwApi} from '@services/api';
import {AppContext} from 'app/context';
import {ChevronDown} from '@gravity-ui/icons';
import {getNounForm} from '@utils';
import {Proposal} from './components/Proposal';
import {useToast} from '@hooks';

const b = block('item');

export interface ItemProps {
    header: ArticleInfoModel;
    proposals: ProposalModel[];
}

export const Item: React.FC<ItemProps> = ({header, proposals}) => {
    const {emit, state} = useContext(AppContext);
    const [loadingAddedItems, setLoadingAddedItems] = useState<{[key: string]: boolean}>({});
    const {showAddToCartErrorToast} = useToast();

    const basketItemsMap = state.basket.items.reduce(
        (acc, item) => {
            acc[item.id] = item.itemsCnt;
            return acc;
        },
        {} as {[key: string]: number}
    );

    // Инициализация addedItems на основе того, есть ли товар в корзине
    const initialAddedItems = proposals.reduce(
        (acc, proposal) => {
            acc[proposal.key] = proposal.basketItemId ? true : false;
            return acc;
        },
        {} as {[key: string]: boolean}
    );
    const [addedItems, setAddedItems] = useState<{[key: string]: boolean}>(initialAddedItems); // состояние добавления товара в корзину

    // Инициализация quantities на основе количества товаров в корзине
    const initialQuantities = proposals.reduce(
        (acc, proposal) => {
            if (proposal.basketItemId && basketItemsMap[proposal.basketItemId]) {
                acc[proposal.key] = basketItemsMap[proposal.basketItemId];
            } else {
                acc[proposal.key] = acc[proposal.key] || proposal.multiplicity;
            }
            return acc;
        },
        {} as {[key: string]: number}
    );
    const [quantities, setQuantities] = useState<{[key: string]: number}>(initialQuantities);

    // Обработчик изменения количества товара
    const handleQuantityChange = (proposalKey: string, newQuantity: number) => {
        setQuantities((prev) => ({
            ...prev,
            [proposalKey]: newQuantity
        }));

        if (addedItems[proposalKey]) {
            // Товар уже добавлен в корзину, нужно обновить количество на сервере
            const basketItemId = proposals.find((p) => p.key === proposalKey)?.basketItemId;

            if (!basketItemId) {
                console.error('Невозможно обновить товар без basketItemId');
                return;
            }

            // Используем комментарий из state.basket.items
            const currentItem = state.basket.items.find((item) => item.id === basketItemId);
            const currentComment = currentItem?.comment || '';

            if (!currentItem) {
                console.error('basketItemId not found');
                return;
            }

            const updatedItem = {
                itemsCnt: newQuantity,
                comment: currentComment
            };

            userGwApi()
                .basket.basketPartialUpdate({[basketItemId]: updatedItem})
                .then(() => {
                    const updatedBasketItem = {
                        ...currentItem,
                        itemsCnt: newQuantity
                    };

                    // Рассчитываем новое значение totalAmount
                    const oldTotalAmount = state.basket.summary.totalAmount || 0;
                    const newTotalAmount =
                        oldTotalAmount -
                        currentItem.price * currentItem.itemsCnt +
                        currentItem.price * newQuantity;

                    emit('updateBasket', {
                        basket: {
                            items: state.basket.items.map((item) =>
                                item.id === basketItemId ? updatedBasketItem : item
                            ),
                            summary: {
                                totalAmount: newTotalAmount
                            }
                        }
                    });
                })
                .catch((error) => {
                    console.error('Ошибка обновления товара в корзине:', error);
                });
        }
    };

    // Обработчик добавления товара в корзину
    const handleAddToBasket = (proposalKey: string) => {
        setLoadingAddedItems((prevLoading) => ({
            ...prevLoading,
            [proposalKey]: true
        }));

        const quantity = quantities[proposalKey] || 1;
        const proposal = proposals.find((p) => p.key === proposalKey);

        if (!proposal) {
            console.error('Proposal not found');
            return;
        }

        const data = {
            [proposalKey]: {
                itemsCnt: quantity,
                comment: ''
            }
        };

        userGwApi()
            .basket.basketPartialUpdate(data)
            .then((response) => {
                const currentCounter = state.counters['basketItems'] || 0;
                const newCounterValue = currentCounter + 1;
                emit('updateCounter', {counterName: 'basketItems', value: newCounterValue});

                const currentTotalAmount = state.basket.summary.totalAmount || 0;
                const newTotalAmount = currentTotalAmount + proposal.price * quantity;

                emit('updateBasket', {
                    basket: {
                        items: [
                            ...state.basket.items.filter(
                                (item) =>
                                    !response.data.items.some(
                                        (resItem) => resItem.id === item.id,
                                    )
                            ),
                            ...response.data.items
                        ],
                        summary: {
                            totalAmount: newTotalAmount
                        }
                    }
                });

                // Обновляем свойство basketItemId у proposal
                proposal.basketItemId = proposal.key;

                setAddedItems((prevAddedItems) => ({
                    ...prevAddedItems,
                    [proposalKey]: true
                }));
            })
            .catch((error) => {
                console.error('Error adding to basket', error);

                showAddToCartErrorToast();
            })
            .finally(() => {
                setLoadingAddedItems((prevLoading) => ({
                    ...prevLoading,
                    [proposalKey]: false
                }));
            });
    };

    // Обработчик удаления товара из корзины
    const handleDeleteFromBasket = (proposalKey: string) => {
        setLoadingAddedItems((prevLoading) => ({
            ...prevLoading,
            [proposalKey]: true
        }));

        const basketItemId = proposals.find((p) => p.key === proposalKey)?.basketItemId;

        if (!basketItemId) {
            console.error('Cannot delete item without basketItemId');
            return;
        }

        const itemToDelete = {
            [basketItemId]: {itemsCnt: 0}
        };

        userGwApi()
            .basket.basketPartialUpdate(itemToDelete)
            .then(() => {
                const currentItem = state.basket.items.find((item) => item.id === basketItemId);
                if (!currentItem) {
                    console.error('Item not found in basket');
                    return;
                }

                const currentTotalAmount = state.basket.summary.totalAmount || 0;
                const newTotalAmount =
                    currentTotalAmount - currentItem.price * currentItem.itemsCnt;

                const currentCounter = state.counters['basketItems'] || 0;
                const newCounterValue = currentCounter - 1;
                emit('updateCounter', {counterName: 'basketItems', value: newCounterValue});

                const updatedItems = state.basket.items.filter((item) => item.id !== basketItemId);

                emit('updateBasket', {
                    basket: {
                        items: updatedItems,
                        summary: {
                            totalAmount: newTotalAmount
                        }
                    }
                });

                const proposal = proposals.find((p) => p.key === proposalKey);
                if (proposal) {
                    proposal.basketItemId = undefined; // Сбрасываем basketItemId после удаления
                }

                setAddedItems((prevAddedItems) => {
                    const updatedItems = {
                        ...prevAddedItems,
                        [proposalKey]: false
                    };
                    return updatedItems;
                });

                setQuantities((prevQuantities) => ({
                    ...prevQuantities,
                    [proposalKey]: 1 // TODO multiplicity
                }));
            })
            .catch((error) => {
                console.error('Error removing from basket', error);
            })
            .finally(() => {
                setLoadingAddedItems((prevLoading) => ({
                    ...prevLoading,
                    [proposalKey]: false
                }));
            });
    };

    const [showMoreToggled, setShowMoreToggled] = useState(false);
    const maxProposalsToShow = 3;
    const showMoreIsVisible = proposals.length > maxProposalsToShow && !showMoreToggled;
    const proposalsToShow = proposals.length <= maxProposalsToShow || showMoreToggled
        ? proposals
        : proposals.slice(0, maxProposalsToShow);
    const shorMoreLength = proposals.length - maxProposalsToShow;
    const showMoreNoun = getNounForm(shorMoreLength, ['предложение', 'предложения', 'предложений']);
    const showMoreText = showMoreToggled ? 'Скрыть' : 'Показать ещё';

    function handleShowMoreClick() {
        setShowMoreToggled((prev) => !prev);
    }

    return (
        <>
            <div className={b()}>
                <ArticleInfo className={b('item-header')} header={header} />
                <div className={b('proposals')}>
                    {proposalsToShow.map((proposal) => {
                        return (
                            <Proposal
                                key={proposal.key}
                                proposal={proposal}
                                addedItems={addedItems}
                                quantities={quantities}
                                loadingAddedItems={loadingAddedItems}
                                handleQuantityChange={handleQuantityChange}
                                handleAddToBasket={handleAddToBasket}
                                handleDeleteFromBasket={handleDeleteFromBasket}
                            />
                        );
                    })}
                </div>
            </div>
            {showMoreIsVisible ? (
                <div className={b('show-more-container')} onClick={handleShowMoreClick}>
                    <span className={(b('show-more'))}>{showMoreText} {shorMoreLength} {showMoreNoun}</span>
                    <ChevronDown className={b('show-more-icon', {rotation: showMoreToggled})} />
                </div>) : (null)}
        </>
    );
};
