import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import block from 'bem-cn-lite';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {
    Breadcrumbs,
    FirstDisplayedItemsCount,
    LastDisplayedItemsCount,
    Text,
    Select,
    User,
} from '@gravity-ui/uikit';
import {House, Handset, ShoppingCart, Box, CircleRuble} from '@gravity-ui/icons';
import LogoPng from '@assets/logo.png';
import {Button} from '@components/shared/button';
import ContactCard from './components/ContactCard/ContactCard';
import UserCard from './components/UserCard/UserCard';
import useHoverCard from '@hooks/useHoverCardHeader';
import { AppContext } from 'app/context';

import './Header.scss';

const b = block('header');

export const Header: React.FC = () => {
    const { emit } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = useContext(AppContext);
    const isWideScreen = useMediaQuery({ query: '(min-width: 1920px)' });

    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {
            text: 'Главная',
            action: () => {
                emit('toggleBrandSearch', { active: false }); // Убираем поиск при клике на "Главная"
                navigate('/home');
            },
        },
    ]);

    useEffect(() => {
        if (location.pathname === '/basket' || location.pathname === '/search') {
            if (state.isBrandSearchActive) {
                emit('toggleBrandSearch', { active: false });
            }
        }

        const newItems = [{ text: 'Главная', action: () => {
            emit('toggleBrandSearch', { active: false });
            navigate('/home');
        } }];

        // Добавляем элемент "Корзина", если находимся на странице корзины
        if (location.pathname === '/basket') {
            if (!newItems.some((item) => item.text === 'Корзина')) {
                newItems.push({
                    text: 'Корзина',
                    action: () => {
                        emit('toggleBrandSearch', { active: false });
                        navigate('/basket');
                    },
                });
            }
        }

        if (location.pathname === '/search') {
            if (!newItems.some((item) => item.text === 'Поиск запчасти по коду')) {
                newItems.push({
                    text: 'Поиск запчасти по коду',
                    action: () => {
                        emit('toggleBrandSearch', { active: false });
                        navigate('/search');
                    },
                });
            }
        }

        // Добавляем элемент "Уточнение запроса", если активен поиск бренда
        if (state.isBrandSearchActive) {
            newItems.push({
                text: 'Уточнение запроса',
                action: () => {},
            });
        }

        setBreadcrumbItems(newItems);
    }, [location, navigate, state.isBrandSearchActive]);

    // Хук для кнопки и ContactCard
    const {
        isVisible: isContactCardVisible,
        handleMouseEnter: handleMouseEnterContact,
        handleMouseLeave: handleMouseLeaveContact,
    } = useHoverCard();

    // Хук для User и UserCard
    const {
        isVisible: isUserCardVisible,
        handleMouseEnter: handleMouseEnterUser,
        handleMouseLeave: handleMouseLeaveUser,
        hideUserCard,
    } = useHoverCard();

    // Получаем количество элементов в корзине
    const basketItemCount = state.counters['basketItems'] || 0;
    const displayCount = basketItemCount > 99 ? `${String(basketItemCount)[0]}..` : basketItemCount;

    return (
        <header className={b()}>
            <div className={b('header-top')}>
                <div className={b('left-section')}>
                    <Link to="/home">
                        <img src={LogoPng} className={b('header-logo')} alt="Авто-Транзит" />
                    </Link>

                    <Button
                        design="white"
                        onClick={() => navigate('/home')}
                        className={b('left-section-button')}
                    >
                        <House width="20" height="20" />
                    </Button>

                    <div
                        onMouseEnter={handleMouseEnterContact}
                        onMouseLeave={handleMouseLeaveContact}
                    >
                        <Button design="white" className={b('left-section-button')}>
                            <Handset width="20" height="20" />
                        </Button>
                        {isContactCardVisible && <ContactCard />}
                    </div>
                </div>

                <div className={b('right-section')}>
                    <Select size="xl" defaultValue={['novosibirsk']}>
                        <Select.Option value="novosibirsk">
                            Новосибирск, ул. Ленина д.1, пом.12
                        </Select.Option>
                        <Select.Option value="val_1">Value 1</Select.Option>
                        <Select.Option value="val_2">Value 2</Select.Option>
                    </Select>

                    <Link to="/basket">
                        <Button design="white" className={b('right-section-button')}>
                            <div className={b('cart-wrapper')}>
                                <ShoppingCart width="20" height="20" />
                                <span className={b('cart-count')}>{displayCount}</span>
                            </div>

                            <Text>Корзина</Text>
                        </Button>
                    </Link>

                    <Button design="white" className={b('right-section-button')}>
                        <Box
                            width="20"
                            height="20"
                        />

                        <Text>Заказы</Text>
                    </Button>

                    <Button design="white" className={b('right-section-button')}>
                        <CircleRuble
                            width="20"
                            height="20"
                        />

                        <Text>Баланс</Text>
                    </Button>

                    <div onMouseEnter={handleMouseEnterUser} onMouseLeave={handleMouseLeaveUser}>
                        <User
                            avatar={{text: 'Сергей Горячев', theme: 'brand'}}
                            name="Сергей"
                            description="user@domain.com"
                            size="l"
                        />
                        {isUserCardVisible && <UserCard onClose={hideUserCard} />}
                    </div>
                </div>
            </div>

            <div className={b('header-bottom')}>
                <nav aria-label="breadcrumb">
                    <Breadcrumbs
                        items={breadcrumbItems}
                        firstDisplayedItemsCount={FirstDisplayedItemsCount.One}
                        lastDisplayedItemsCount={LastDisplayedItemsCount.One}
                    />
                </nav>

                <div className={b('text-elements')}>
                    <div className={b('text-element')}>
                        <Text variant={isWideScreen ? 'body-2' : 'body-1'}>Просроченная задолженность:</Text>
                        <Text className={b('red-text-total')} variant={isWideScreen ? 'body-2' : 'body-1'}>- 2 000 000 ₽</Text>
                    </div>
                    <div className={b('text-element')}>
                        <Text variant={isWideScreen ? 'body-2' : 'body-1'}>Min оплата на завтра:</Text>
                        <Text className={b('text-total')} variant={isWideScreen ? 'body-2' : 'body-1'}>1 000 000 ₽</Text>
                    </div>
                    <div className={b('text-element')}>
                        <Text variant={isWideScreen ? 'body-2' : 'body-1'}>Доступно для заказа:</Text>
                        <Text className={b('text-total')} variant={isWideScreen ? 'body-2' : 'body-1'}>5 000 000 ₽</Text>
                    </div>
                </div>
            </div>
        </header>
    );
};
