import React from 'react';
import block from 'bem-cn-lite';
import {Link} from 'react-router-dom';
import {Text} from '@gravity-ui/uikit';

import BasketEmptyPng from '@assets/basket-empty.png';

import './BasketEmpty.scss';

const b = block('basket-empty');

export const BasketEmpty = () => {
    return (
        <div className={b()}>
            <img className={b('image')} src={BasketEmptyPng}></img>
            <Text variant={'subheader-2'}>В корзине пусто.</Text>
            <Text variant={'body-2'}>
                Начните{' '}

                <Link to="/home" className={b('link')}>
                    <Text variant={'body-2'} color={'link'}>
                        поиск
                    </Text>
                </Link>
                
            </Text>
        </div>
    );
};
