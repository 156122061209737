import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {CApi as userGwApi} from '@services/api';
import {
    AsyncSearchModel,
    AsyncSearchState,
    FilterRangeModel,
    FiltersModel,
    SearchInputModel,
    SearchModel,
    SearchRequest,
    SearchState,
    SortOption,
} from '@features/search/interfaces';
import {
    getDefaultAsyncSearchModel,
    getDefaultFilters,
    getDefaultSearchModel,
    getDefaultSortOptions,
    getFilteredAndSorted,
    isEmpty,
    processSearchApiResults,
} from '@features/search/services';
import {useSearchHistory} from '@features/search/hooks/useSearchHistory';
import {Proposal} from '@services/user-gw';

interface SearchParams {
    searchText: SearchInputModel;
    setSearchText: Dispatch<SetStateAction<SearchInputModel>>;
    searchBrand: string;
    useAnalogs: boolean;
}

export function useSearchResults({
    searchBrand,
    searchText,
    useAnalogs,
    setSearchText
}: SearchParams): [
    SearchModel,
    SearchState,
    SortOption[],
    FiltersModel,
    boolean,
    SearchRequest[],
    AsyncSearchModel,
    (filters: FiltersModel) => void,
    (sortOptions: SortOption[]) => void
] {

    const searchRequestRef = useRef<SearchRequest>({
        brand: '',
        article: '',
    });
    const asyncKeyRef = useRef<string>();
    const apiResults = useRef<Proposal[]>([]);
    const apiAsyncGeneration2Results = useRef<Proposal[]>([]);
    const sourceResults = useRef<SearchModel>(getDefaultSearchModel());
    const sortOptionsRef = useRef<SortOption[]>(getDefaultSortOptions());
    const filtersRef = useRef<FiltersModel>(getDefaultFilters());

    const [results, setResults] = useState<SearchModel>(sourceResults.current);
    const [filters, setFilters] = useState<FiltersModel>(filtersRef.current);
    const [sortOptions, setSortOptions] = useState<SortOption[]>(sortOptionsRef.current);
    const [searchState, setSearchState] = useState<SearchState>(SearchState.None);
    const [showClearFilters, setShowClearFilters] = useState<boolean>(false);
    const [searchHistory, setSearchHistory] = useSearchHistory();
    const [asyncSearchModel, setAsyncSearchModel] = useState<AsyncSearchModel>(
        getDefaultAsyncSearchModel(showAsyncGen2Results));

    function resetGeneration(results: SearchModel) {
        results.searched.forEach(x => x.proposals.forEach(p => p.generation = 0));
        results.original.forEach(x => x.proposals.forEach(p => p.generation = 0));
        results.analogs.forEach(x => x.proposals.forEach(p => p.generation = 0));
    }

    function scheduleGeneration1Query(asyncKey: string, searchRequest: SearchRequest) {
        const generationTimeout = 1000;
        const asyncKeyForCheck = asyncKey;

        setAsyncSearchModel((prev) => {
            return {
                ...prev,
                state: AsyncSearchState.Searching,
            };
        });

        setTimeout(() => {
            if (asyncKeyForCheck !== asyncKeyRef.current)
                return;

            userGwApi().suggest
                .asyncProposalsDetail(asyncKey)
                .then((r) => {
                    if (asyncKeyForCheck !== asyncKeyRef.current)
                        return;

                    console.log(`async query results: ${r.data.items.length}`);

                    const resultsToProcess = apiResults.current.length > 0 ?
                        [apiResults.current, r.data.items] :
                        [r.data.items];
                    const [newResults, newFilters] = processSearchApiResults({
                        searchResults: resultsToProcess,
                        searchRequest,
                    });
                    apiResults.current = [...apiResults.current, ...r.data.items];
                    sourceResults.current = newResults;
                    filtersRef.current = mergeSelectedValues(newFilters, filtersRef.current);

                    const filteredAndSorted = getFilteredAndSorted(
                        newResults,
                        filtersRef.current,
                        sortOptionsRef.current,
                    );

                    setFilters(filtersRef.current);
                    setResults(filteredAndSorted);

                    const isEmptyAfterFetch = isEmpty(newResults);
                    setSearchState(isEmptyAfterFetch ? SearchState.Empty : SearchState.Found);

                    if (r.data.state === 'in_progress')
                        scheduleGeneration2Query(asyncKey);
                    else
                        setAsyncSearchModel((prev) => {
                            return {...prev, state: AsyncSearchState.None};
                        });
                })
            ;
        }, generationTimeout);
    }

    function scheduleGeneration2Query(asyncKey: string) {
        const generationTimeout = 9000;
        const asyncKeyForCheck = asyncKey;

        setTimeout(() => {
            if (asyncKeyForCheck !== asyncKeyRef.current)
                return;

            userGwApi().suggest
                .asyncProposalsDetail(asyncKey)
                .then((r) => {
                    if (asyncKeyForCheck !== asyncKeyRef.current)
                        return;
                    console.log(`async query results: ${r.data.items.length}`);

                    apiAsyncGeneration2Results.current = r.data.items;
                    setAsyncSearchModel((prev) => {
                        return {
                            ...prev,
                            state: AsyncSearchState.None,
                            length: r.data.items.length,
                        };
                    });
                })
            ;
        }, generationTimeout);
    }

    function showAsyncGen2Results() {
        const [newResults, newFilters] = processSearchApiResults({
            searchResults: [apiResults.current, apiAsyncGeneration2Results.current],
            searchRequest: searchRequestRef.current,
        });
        apiResults.current = [...apiResults.current, ...apiAsyncGeneration2Results.current];
        sourceResults.current = newResults;
        filtersRef.current = mergeSelectedValues(newFilters, filtersRef.current);

        const filteredAndSorted = getFilteredAndSorted(newResults, filtersRef.current, sortOptionsRef.current);

        setFilters(filtersRef.current);
        setResults(filteredAndSorted);
        setAsyncSearchModel((prev) => {
            return {...prev, length: 0};
        });
    }

    function mergeSelectedValues(newFilters: FiltersModel, currentFilters: FiltersModel): FiltersModel {
        const nf = newFilters;
        const cf = currentFilters;

        return {
            brands: nf.brands.map(x => {
                const cBrands = cf.brands.filter((cx) => cx.brandName === x.brandName);
                const isSelected = cBrands.length === 1 ? cBrands[0].isSelected : false;
                return {
                    brandName: x.brandName,
                    minPrice: x.minPrice,
                    isSelected: isSelected,
                };
            }),
            priceRange: mergeRange(nf.priceRange, cf.priceRange),
            deliveryDayRange: mergeRange(nf.deliveryDayRange, cf.deliveryDayRange),
            ratingRange: mergeRange(nf.ratingRange, cf.ratingRange),
            quantityRange: mergeRange(nf.quantityRange, cf.quantityRange),
            priceLevel: nf.priceLevel,
            isReturnPossible: nf.isReturnPossible,
            isOfficialDealer: nf.isOfficialDealer,
        } as FiltersModel;
    }

    function mergeRange(newRange: FilterRangeModel, currentRange: FilterRangeModel): FilterRangeModel {
        return {
            ...newRange,
            from: getFromOrUndefined(newRange.min, currentRange.from),
            to: getToOrUndefined(newRange.max, currentRange.to),
        };
    }

    function getFromOrUndefined(min: number | undefined, from: number | undefined): number | undefined {
        if (min === undefined || from === undefined)
            return undefined;

        return (from < min) ? undefined : from;
    }

    function getToOrUndefined(max: number | undefined, to: number | undefined): number | undefined {
        if (max === undefined || to === undefined)
            return undefined;

        return (to > max) ? undefined : to;
    }

    useEffect(() => {
        if (!searchBrand) return;

        let ignore = false;

        searchRequestRef.current = {
            article: searchText.cleanedValue,
            brand: searchBrand,
        } as SearchRequest;

        // clear everything
        setSearchState(SearchState.Searching);
        asyncKeyRef.current = undefined;
        apiResults.current = [];
        apiAsyncGeneration2Results.current = [];
        sourceResults.current = getDefaultSearchModel();
        sortOptionsRef.current = getDefaultSortOptions();
        filtersRef.current = getDefaultFilters();
        setFilters(filtersRef.current);
        setSortOptions(sortOptionsRef.current);
        setResults(sourceResults.current);
        setAsyncSearchModel(getDefaultAsyncSearchModel(showAsyncGen2Results));

        const resultsPromise = userGwApi().suggest.suggestCreate({
            ...searchRequestRef.current,
            useAnalogs: useAnalogs,
        });

        resultsPromise
            .then((r) => {
                if (!ignore) {
                    apiResults.current = r.data.items;

                    const [results, filters] = processSearchApiResults({
                        searchResults: [r.data.items],
                        searchRequest: searchRequestRef.current,
                    });
                    sourceResults.current = results;
                    asyncKeyRef.current = r.data.asyncKey;

                    filtersRef.current = filters;
                    setFilters(filtersRef.current);

                    const filteredAndSorted = getFilteredAndSorted(results, filtersRef.current, sortOptionsRef.current);
                    setResults(filteredAndSorted);

                    const isEmptyAfterFetch = isEmpty(results);

                    if (!isEmptyAfterFetch) {
                        setSearchState(SearchState.Found);
                        setSearchText((prev) => {
                            return {
                                cleanedValue: prev.cleanedValue,
                                originalValue: prev.cleanedValue,
                                needToSuggestBrands: false
                            };
                        });
                    }

                    if (asyncKeyRef.current)
                        scheduleGeneration1Query(asyncKeyRef.current, searchRequestRef.current);
                }
            })
            .catch((e) => {
                console.log(e);
                // clearResults();
            })
            .finally(() => addToSearchHistory(searchRequestRef.current));

        return () => {
            ignore = true;
        };

    }, [searchBrand, useAnalogs]);

    function handleFiltersChanged(filters: FiltersModel) {
        resetGeneration(sourceResults.current);
        const filteredAndSortedResults = getFilteredAndSorted(sourceResults.current, filters, sortOptionsRef.current);
        const isEmptyAfterFilters = isEmpty(filteredAndSortedResults);
        filtersRef.current = filters;
        setFilters(filtersRef.current);
        setResults(filteredAndSortedResults);
        setSearchState(isEmptyAfterFilters ? SearchState.Empty : SearchState.Found);
        setShowClearFilters(!isEmpty(sourceResults.current));
    }

    function handleSortOptionChanged(sortOptions: SortOption[]) {
        resetGeneration(sourceResults.current);
        sortOptionsRef.current = sortOptions;
        setSortOptions(sortOptionsRef.current);
        const filteredAndSortedResults = getFilteredAndSorted(sourceResults.current, filters, sortOptionsRef.current);
        setResults(filteredAndSortedResults);
    }

    function addToSearchHistory(searchRequest: SearchRequest) {
        setSearchHistory((prev) => {
            const excludeCurrent = () =>
                prev.filter(
                    (x) => x.article !== searchRequest.article || x.brand !== searchRequest.brand,
                );

            return [searchRequest, ...excludeCurrent().slice(0, 9)];
        });
    }

    return [
        results,
        searchState,
        sortOptions,
        filters,
        showClearFilters,
        searchHistory,
        asyncSearchModel,
        handleFiltersChanged,
        handleSortOptionChanged,
    ];
}