import { ReactNode, useState } from 'react';
import { ToasterProvider } from '@gravity-ui/uikit';
import { ToastVisibilityContext } from './ToastVisibilityContext';
import { CustomToaster } from './CustomToaster';

interface CustomToasterProviderProps {
    children: ReactNode;
}

export const CustomToasterProvider: React.FC<CustomToasterProviderProps> = ({ children }) => {
    const [isToasterVisible, setIsToasterVisible] = useState(false);
    const [currentToastType, setCurrentToastType] = useState<string | null>(null);

    return (
        <ToasterProvider>
            <ToastVisibilityContext.Provider value={{ isToasterVisible, setIsToasterVisible, currentToastType, setCurrentToastType }}>
                {children}
                <CustomToaster />
            </ToastVisibilityContext.Provider>
        </ToasterProvider>
    );
};
