import React from 'react';
import block from 'bem-cn-lite';
import './Price.scss';
import {Text} from '@gravity-ui/uikit';
import {formatCurrencyRub} from '@utils';

const b = block('price');

export interface PriceProps {
    value: number;
    className: string;
    newValue?: number;
    showOldUnitPrice?: boolean; // флажок, чтобы показать старую цену за единицу под общей суммой
    oldUnitPrice?: number;
    color?: 'warning' | 'positive';
}

export const Price: React.FC<PriceProps> = (props) => {
    const { value, newValue, showOldUnitPrice, oldUnitPrice, className, color } = props;

    return (
        <div className={b() + ' ' + className}>
            {newValue ? (
                // Отображение новых и старых цен за единицу товара
                <>
                    <Text variant={'subheader-2'} color={color}>
                        {formatCurrencyRub(newValue)} {/* новая цена */}
                    </Text>
                    <Text color={'secondary'} className={b('old-price')}>
                        <s>{formatCurrencyRub(value)}</s> {/* Старая цена за единицу */}
                    </Text>
                </>
            ) : (
                // Отображение основного значения (цена за единицу или общая сумма)
                <Text variant={'subheader-2'} color={showOldUnitPrice ? color : undefined}>
                    {formatCurrencyRub(value)}
                </Text>
            )}
            {showOldUnitPrice && oldUnitPrice && !newValue && (
                // Отображать старую цену за единицу под общей суммой, если новое значение не указано
                <Text color={'secondary'} className={b('old-price')} >
                    <s>{formatCurrencyRub(oldUnitPrice)}</s> {/* Старая цена за единицу */}
                </Text>
            )}
        </div>
    );
};
