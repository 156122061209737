import { useContext } from 'react';
import block from 'bem-cn-lite';
import { ToasterComponent } from '@gravity-ui/uikit';
import { ToastVisibilityContext } from './ToastVisibilityContext';

import './CustomToaster.scss';

const b = block('custom-toaster');

export const CustomToaster: React.FC = () => {
    const { isToasterVisible, currentToastType } = useContext(ToastVisibilityContext);

    if (!isToasterVisible || currentToastType === 'success') return null;

    return (
        <div className={b()}>
            <ToasterComponent />
        </div>
    );
};