import {useEffect, useRef, useContext} from 'react';
import {useToaster, ButtonView, Button, Text} from '@gravity-ui/uikit';
import {ToastVisibilityContext} from 'app/Providers/CustomToasterProvider/ToastVisibilityContext';

export const useToast = () => {
    const {add, has, remove} = useToaster();
    const {isToasterVisible, setIsToasterVisible, setCurrentToastType, currentToastType} =
        useContext(ToastVisibilityContext);
    const toastNameRef = useRef<string | null>(null);

    const showToast = (type: 'success' | 'danger' | 'warning', message: string) => {
        setIsToasterVisible(true);
        setCurrentToastType(type);

        // Текст для content, если это danger или warning
        const additionalContent =
            type === 'warning'
                ? 'Вы можете найти замену, удалить товары из корзины или продолжить оформление.'
                : type === 'danger'
                  ? 'Вы можете найти замену или удалить эти товары из корзины.'
                  : null;

        const actions =
            type === 'warning' || type === 'danger'
                ? [
                      {
                          label: 'Ок, понятно',
                          onClick: () => {
                              console.log('Action clicked');
                              setIsToasterVisible(false);
                          },
                          view: 'normal-contrast' as ButtonView,
                          removeAfterClick: true,
                      },
                  ]
                : [];

        const toastName = `basket-check-${Date.now()}`;
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: message,
            content: additionalContent,
            theme: type,
            autoHiding: type === 'success' ? 5000 : false,
            actions,
            isClosable: true,
        });

        if (type === 'success') {
            setTimeout(() => {
                setIsToasterVisible(false);
            }, 5000);
        }
    };

    const showAddToCartErrorToast = () => {
        setIsToasterVisible(true);
        setCurrentToastType('danger');

        const toastName = 'add_to_basket_error';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: 'Произошла ошибка при добавлении товара в корзину',
            content: (
                <>
                    <Text variant="body-2">Попробуйте еще раз.</Text>
                    <Button
                        size="l"
                        view="normal-contrast"
                        onClick={() => {
                            remove(toastName);
                            setIsToasterVisible(false);
                        }}
                        style={{marginTop: '8px', width: '100%'}}
                    >
                        Ок, попробую еще раз
                    </Button>
                </>
            ),
            theme: 'danger',
            autoHiding: false,
            isClosable: true,
        });
    };

    // Отслеживаем удаление тоста
    useEffect(() => {
        if (!toastNameRef.current) return;

        const interval = setInterval(() => {
            if (!has(toastNameRef.current!)) {
                setIsToasterVisible(false);
                clearInterval(interval);
                toastNameRef.current = null; // Сбрасываем имя тоста после его закрытия
            }
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [has, setIsToasterVisible, isToasterVisible]);

    // Для изменения позиции тостера при отсутсии измененений в товарах
    useEffect(() => {
        const root = document.documentElement;

        if (currentToastType === 'success') {
            root.style.setProperty('--g-toaster-inset-block-end', '75%');
        } else {
            root.style.setProperty('--g-toaster-inset-block-end', '50%');
        }

        return () => {
            // сбросить переменную при размонтировании
            root.style.removeProperty('--g-toaster-inset-block-end');
        };
    }, [currentToastType]);

    return {showToast, isToasterVisible, showAddToCartErrorToast};
};
