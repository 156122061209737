import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Item, SectionHeader, SectionItems} from './components';
import {SectionItemModel} from '@features/search/interfaces';
import './Section.scss';

const b = block('section');

interface SectionProps {
    title: string;
    showSectionHeader: boolean;
    items: SectionItemModel[];
}

export const Section: React.FC<SectionProps> = ({
    title,
    showSectionHeader,
    items
}) => {
    const [isHidden, setIsHidden] = useState(false);

    function toggleVisibility() {
        setIsHidden((prev) => !prev);
    }

    return items.length >= 1 ?
        (<div className={b({hidden: isHidden})}>
            <SectionHeader
                title={title}
                isHidden={isHidden}
                onToggleVisibility={toggleVisibility}
                isTopLevel={showSectionHeader}
            />
            <SectionItems hiddenSection={isHidden}>
                {items.map((item) => {
                    return (
                        <Item key={item.key} header={item.header} proposals={item.proposals}></Item>
                    );
                })}
            </SectionItems>
        </div>) : null;
};
