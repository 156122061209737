import {BasketItemModel} from "../interfaces";

// Приоритетные поля сортировки по умолчанию
export const defaultSortPriority = ['price', 'deliveryDays', 'rating', 'quantity'];
    
// Направление сортировки по умолчанию
export const defaultDirection = new Map<string, number>([
    ['price', 1],
    ['deliveryDays', 1],
    ['rating', -1],
    ['quantity', -1],
]);

export function sortItems(
    items: BasketItemModel[] | undefined,
    sortOption: { property: string; direction: 'asc' | 'desc' } | null,
    sortPriority: string[],
    defaultSortPriority: string[],
    defaultDirection: Map<string, number>
) {
    if (!items) return [];

    return items.slice().sort((a, b) => {
        // Создаем текущий приоритет сортировки
        let currentSortPriority: string[] = [];

        if (sortOption) {
            currentSortPriority.push(sortOption.property);
            currentSortPriority = currentSortPriority.concat(sortPriority.filter(item => item !== sortOption.property));
        } else {
            currentSortPriority = [...sortPriority];
        }

        for (const property of currentSortPriority) {
            let aValue = getSortValue(a, property);
            let bValue = getSortValue(b, property);

            let comparison = compareValues(aValue, bValue, property);

            if (comparison !== 0) {
                let direction: number;
                if (sortOption && sortOption.property === property) {
                    direction = sortOption.direction === 'asc' ? 1 : -1;
                } else {
                    direction = defaultDirection.get(property) || 1;
                }
                return comparison * direction;
            }
        }

        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
}


function getSortValue(item: BasketItemModel, sortField: string) {
    switch (sortField) {
        case 'price':
            return item.price;
        case 'deliveryDays':
            return item.delivery.expected.client.days;
        case 'rating':
            return item.rating;
        case 'quantity':
            return item.quantity;
        case 'delivery-date':
            return new Date(item.delivery.expected.client.date).getTime();
        case 'date':
            return new Date(item.createdAt).getTime();
        default:
            return 0;
    }
}

function compareValues(a: any, b: any, field: string) {
    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    }
    if (typeof a === 'string' && typeof b === 'string') {
        // Возвращаем число, показывающее порядок строк
        return a.localeCompare(b);
    }
    return 0;
}