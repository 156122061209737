import {FC} from 'react';
import {Button, Text} from '@gravity-ui/uikit';
import {ShoppingCart} from '@gravity-ui/icons';
import block from 'bem-cn-lite';
import './BasketButton.scss';
import {useNavigate} from 'react-router-dom';

const b = block('basket-btn');

export interface BasketButtonProps {
    totalItems: number | undefined;   // Общее количество товаров
    totalAmount: number | undefined;  // Общая сумма стоимости товаров
}

export const BasketButton: FC<BasketButtonProps> = (props) => {
    const navigateTo = useNavigate();

    const formatNumber = new Intl.NumberFormat(
        'ru-RU',
        {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 0,
        },
    );

    function handleClick() {
        navigateTo('/basket');
    }

    return (
        <div className={b('container')}>
            <Button
                className={b('button')}
                size="xl"
                view="normal"
                type="button"
                onClick={handleClick}
            >
                <div className={b('block')}>
                    <div className={b('quantity', {hidden: !props.totalItems})}>
                        <Text children={props.totalItems} />
                    </div>
                    <ShoppingCart className={b('icon')} />
                    <span className={b('cost')}>{
                        formatNumber.format(props.totalAmount != null && props.totalAmount !== 0 ? props.totalAmount : 0)
                    }</span>
                </div>
            </Button>
        </div>
    );
};