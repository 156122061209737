import {FiltersModel} from '@features/search/interfaces';

export function getDefaultFilters(): FiltersModel {
    return {
        brands: [],
        deliveryDayRange: {},
        priceRange: {},
        quantityRange: {},
        ratingRange: {},
        isOfficialDealer: false,
        isReturnPossible: false,
        priceLevel: false,
    };
}