import React from 'react';
import block from 'bem-cn-lite';
import './BasketControls.scss';
import {Button} from '@gravity-ui/uikit';
import {ShoppingCart, Check} from '@gravity-ui/icons';
import {QuantityInput} from '@components';

const b = block('basket-controls');

export interface BasketControlsProps {
    value: number;
    multiplicity: number;
    className: string;
    onQuantityChange: (quantity: number) => void;
    onAddToBasket: () => void;
    maxQuantity: number;
    isAdded: boolean;
    loading: boolean;
    onDelete: () => void;
}

export const BasketControls: React.FC<BasketControlsProps> = ({
    className,
    value,
    multiplicity,
    onQuantityChange,
    onAddToBasket,
    maxQuantity,
    isAdded,
    loading,
    onDelete
}) => {
    const buttonClass = isAdded ? 'basket-button-added' : 'basket-button';

    const handleDecrease = isAdded && value === multiplicity ? onDelete : undefined;

    return (
        <div className={b(null, className)}>
            <QuantityInput
                value={value}
                multiplicity={multiplicity}
                onChange={onQuantityChange}
                maxQuantity={maxQuantity}
                onDecrease={handleDecrease}
            />
            <Button
                onClick={isAdded ? onDelete : onAddToBasket}
                size="m"
                className={b('button', buttonClass)}
                view="action"
                disabled={loading}
                loading={loading}
            >
                {isAdded ? <Check className={b('check-icon')} /> : <ShoppingCart className={b('cart-icon')} />}
            </Button>
        </div>
    );
};
