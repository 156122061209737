import { AppContext } from 'app/context';
import { useContext, useCallback } from 'react';
import { BasketItemModel } from '../interfaces';
import {CApi as userGwApi} from '@services/api';
import { BasketPatch } from '@services/user-gw';

export const useBasket = () => {
    const { state, emit } = useContext(AppContext);
    const { basket } = state;

    const handleItemQuantityChange = useCallback((id: string, newQuantity: number) => {
        // Обновляем количество и сумму для нужного элемента в корзине
        const updatedItems = basket.items.map((item) => {
            if (item.id === id) {
                const amount = item.newPrice
                    ? item.newPrice * newQuantity
                    : item.price * newQuantity;
                return {
                    ...item,
                    itemsCnt: newQuantity,
                    amount: amount,
                };
            }
            return item;
        });

        // Находим текущий комментарий для этого товара
        const currentComment = basket.items.find(item => item.id === id)?.comment || '';

        // Формируем данные для обновления на сервере (количество и комментарий)
        const updatedItem = {
            itemsCnt: newQuantity,
            comment: currentComment, // Сохраняем текущий комментарий
        };

        emit('updateBasket', {
            basket: {
                items: updatedItems, // Обновленные товары
            },
        });

        // Отправляем запрос на сервер для обновления количества и комментария
        userGwApi()
            .basket.basketPartialUpdate({ [id]: updatedItem })
            .then(() => {
                console.log('Количество и комментарий успешно обновлены на сервере');
            })
            .catch((error) => {
                console.error("Ошибка при обновлении количества и комментария на сервере:", error);
            });
    }, [basket.items, emit]);

    const handleItemCheckChange = useCallback((id: string, checked: boolean) => {
        const updatedItems = basket.items.map((item) => {
            // Возвращаем новый объект для каждого измененного элемента, чтобы избежать мутации старого состояния
            if (item.id === id) {
                return { ...item, checked };
            }
            return item;
        });

        emit('updateBasket', {
            basket: {
                items: updatedItems,
            },
        });
    }, [basket.items, emit]);

    const handleItemOutCheckChange = useCallback((id: string, checked: boolean) => {
        const updatedItemsOut = basket.itemsOut.map((item) => 
            item.id === id ? { ...item, checked } : item
        );

        emit('updateBasket', {
            basket: {
                itemsOut: updatedItemsOut,
            },
        });
    }, [basket.itemsOut, emit]);

    const handleSelectAll = useCallback((checked: boolean) => {
        const updatedItems = basket.items.map((item: BasketItemModel) => ({
            ...item,
            checked,
        }));

        emit('updateBasket', {
            basket: {
                items: updatedItems,
            },
        });
    }, [basket.items, emit]);

    const handleSelectAllItemsOut = useCallback((checked: boolean) => {
        const updatedItemsOut = basket.itemsOut.map((item) => ({
            ...item,
            checked,
        }));

        emit('updateBasket', {
            basket: {
                itemsOut: updatedItemsOut,
            },
        });
    }, [basket.itemsOut, emit]);

    const handleDeleteSelected = useCallback(() => {
        const selectedItems: BasketPatch = basket.items.reduce((acc, item) => {
            if (item.checked) {
                acc[item.id] = { itemsCnt: 0 };
            }
            return acc;
        }, {} as BasketPatch);

        // Проверяем, есть ли выбранные элементы
        if (Object.keys(selectedItems).length === 0) {
            console.log("Нет выбранных элементов для удаления");
            return;
        }

        userGwApi()
            .basket.basketPartialUpdate(selectedItems)
            .then(() => {
                // Если запрос успешен, обновляем глобальное состояние корзины
                const updatedItems = basket.items.filter((item) => !item.checked);

                emit('updateBasket', {
                    basket: {
                        items: updatedItems,
                    },
                });

                emit('updateCounter', {
                    counterName: 'basketItems',
                    value: updatedItems.length,
                });
            })
            .catch((error) => {
                console.error("Ошибка при удалении элементов:", error);
            });
    }, [emit, basket.items]);

    const handleDeleteItem = useCallback((id: string) => {
        const itemToDelete: BasketPatch = {
            [id]: { itemsCnt: 0 },
        };

        userGwApi()
            .basket.basketPartialUpdate(itemToDelete)
            .then(() => {
                // Если запрос успешен, обновляем глобальное состояние корзины
                const updatedItems = basket.items.filter((item) => item.id !== id);

                emit('updateBasket', {
                    basket: {
                        items: updatedItems, // Обновленные товары после удаления
                    },
                });

                emit('updateCounter', {
                    counterName: 'basketItems',
                    value: updatedItems.length,
                });
            })
            .catch((error) => {
                console.error("Ошибка при удалении элемента:", error);
            });
    }, [emit, basket.items]);

    const handleDeleteItemOut = useCallback((id: string) => {
        const itemToDelete: BasketPatch = {
            [id]: { itemsCnt: 0 },
        };

        userGwApi()
            .basket.basketPartialUpdate(itemToDelete)
            .then(() => {
                // Если запрос успешен, обновляем глобальное состояние itemsOut
                const updatedItemsOut = basket.itemsOut.filter((item) => item.id !== id);

                emit('updateBasket', {
                    basket: {
                        itemsOut: updatedItemsOut,
                    },
                });

                emit('updateCounter', {
                    counterName: 'basketItems',
                    value: updatedItemsOut.length,
                });
            })
            .catch((error) => {
                console.error("Ошибка при удалении элемента:", error);
            });
    }, [emit, basket.itemsOut]);

    const refreshBasketItems = useCallback(async () => {
        const itemIds = basket.items.map((item) => item.id);

        try {
            const response = await userGwApi().basket.refreshCreate({
                items: itemIds,
            });

            const refreshedItems = response.data.items;
            console.log('Актуализация корзины завершена:', refreshedItems);

            let priceChanged = false;
            let quantityChanged = false;
            let itemsUnavailable = false;
            let itemsCntExceedsQuantity = false;

            const updatedItems = basket.items.map((item) => {
                const refreshedItem = refreshedItems[item.id];
                if (refreshedItem) {
                    const prevPrice = item.price;
                    const newPrice = refreshedItem.new.price;
                    const prevQuantity = item.quantity;
                    const newQuantity = refreshedItem.new.quantity;

                    if (prevPrice !== newPrice) {
                        priceChanged = true;
                        item.oldPrice = prevPrice;
                        item.price = newPrice;
                    }

                    if (prevQuantity !== newQuantity) {
                        quantityChanged = true;

                        if (prevQuantity > 0 && newQuantity === 0) {
                            itemsUnavailable = true;
                            // Помечаем товар как ставший недоступным
                            item.quantityChangedToZero = true;
                        } else if (prevQuantity > newQuantity && newQuantity > 0) {
                            // Количество уменьшилось, но товар доступен
                            if (item.itemsCnt > newQuantity) {
                                itemsCntExceedsQuantity = true;
                                // Помечаем товар как имеющий уменьшение доступного количества
                                item.quantityDecreased = true;
                            }
                        }
                    }

                    return {
                        ...item,
                        quantity: newQuantity,
                        previousQuantity: prevQuantity,
                    };
                } else {
                    return item;
                }
            });

            const availableItems = updatedItems.filter((item) => item.quantity > 0);
            const unavailableItems = updatedItems.filter((item) => item.quantity === 0);

            emit('updateBasket', {
                basket: {
                    items: availableItems, // Доступные товары
                    itemsOut: [...basket.itemsOut, ...unavailableItems], // Недоступные товары добавляем к itemsOut
                },
            });

            return {
                priceChanged,
                quantityChanged,
                itemsUnavailable,
                itemsCntExceedsQuantity,
            };
        } catch (error) {
            console.error("Ошибка при актуализации корзины:", error);
            throw error;
        }
    }, [emit, basket.items, basket.itemsOut]);


    return {
        ...basket,
        handleItemQuantityChange,
        handleItemCheckChange,
        handleItemOutCheckChange,
        handleSelectAll,
        handleSelectAllItemsOut,
        handleDeleteSelected,
        handleDeleteItem,
        handleDeleteItemOut,
        refreshBasketItems
    };
};