import React, { useState } from 'react';
import block from 'bem-cn-lite';
import {Button, Tooltip, Text} from '@gravity-ui/uikit';
import {CommentPlus, Magnifier, TrashBin} from '@gravity-ui/icons';
import { BasketItemModel } from '@features/basket/interfaces';
import { BasketTrashModal } from '@features/basket/components/BasketTrashModal';

const b = block('aux-controls');

export interface AuxControlsProps {
    className?: string;
    onDeleteItem: () => void;
    item: BasketItemModel;
}

export const AuxControls: React.FC<AuxControlsProps> = ({className, onDeleteItem, item}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDeleteClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        onDeleteItem();
        setIsModalOpen(false);
    };

    const handleSearchClick = () => {
        const url = `/search?text=${item.article}&brand=${item.brandName}&useAnalogs=false`;
        window.open(url, '_blank');
    }

    return (
        <div className={b(null, className)}>
            <Button disabled={true} view={'outlined'} className={b('button', b('comment'))}>
                <CommentPlus />
            </Button>

            <Tooltip
                content={
                    <Text className={b('tooltip-content')}>
                        Поиск замены. <br />
                        Откроется в новом окне
                    </Text>
                }
                placement="top-end"
                contentClassName={b('tooltip')}
                className={b('tooltip-container')}
                openDelay={0}
                closeDelay={0}
                disabled={false}
            >
                <Button view={'outlined-warning'} className={b('button', b('search'))} onClick={handleSearchClick}>
                    <Magnifier />
                </Button>
            </Tooltip>

            <Button view={'outlined'} className={b('button', b('delete'))} onClick={handleDeleteClick}>
                <TrashBin />
            </Button>

            <BasketTrashModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};
