import React, {useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Avatar} from '@gravity-ui/uikit';
import {Xmark, Person, ArrowRightFromSquare} from '@gravity-ui/icons';
import {Button} from '@components/shared/button';

import './UserCard.scss';
import {AppContext} from '../../../../app/context';

const b = block('user-card');

interface UserCardProps {
    onClose: () => void;
}

const UserCard: React.FC<UserCardProps> = ({onClose}) => {
    const {emit} = useContext(AppContext);

    function handleLogout() {
        emit('logout', {redirectTo: '/'});
    }

    return (
        <div className={b()}>
            <div className={b('header')}>
                <Text className={b('title')}>Профиль</Text>
                <Xmark className={b('close')} onClick={onClose} />
            </div>

            <div className={b('user')}>
                <Avatar text="Сергей Горячев" theme="brand" size="l" />
                <Text className={b('name')}>Сергей Иванович Горячев</Text>
                <Text className={b('company')}>ООО "Авто-транзит"</Text>
            </div>

            <Button size="l" className={b('button-profile')}>
                <Person /> Перейти в профиль
            </Button>
            <Button size="l" view="flat" className={b('button-exit')} onClick={handleLogout}>
                <ArrowRightFromSquare /> Выйти из профиля
            </Button>
        </div>
    );
};

export default UserCard;
