import React, {ChangeEvent} from 'react';
import {useMediaQuery} from 'react-responsive';
import block from 'bem-cn-lite';
import './Items.scss';
import {
    ArticleInfo,
    DeliveryDate,
    DeliveryDays,
    Price,
    ProposalBadges,
    Quantity,
    QuantityInput,
    Rating,
} from '@components';
import {Checkbox, Text} from '@gravity-ui/uikit';
import {AuxControls} from './components';
import {formatDate} from '@utils';
import {useSortedItems} from '@features/basket/hooks/useSortedItems';
import {useBasket} from '@features/basket/hooks/useBasket';

const b = block('items');

interface ItemsProps {
    onItemQuantityChange: (id: string, newQuantity: number) => void;
    onItemCheckChange: (id: string, checked: boolean) => void;
    onDeleteItem: (id: string) => void;
    sortOption: {property: string; direction: 'asc' | 'desc'} | null;
    sortPriority: string[];
    defaultSortPriority: string[];
    defaultDirection: Map<string, number>;
}

export const Items: React.FC<ItemsProps> = ({
    onItemQuantityChange,
    onItemCheckChange,
    onDeleteItem,
    sortOption,
    sortPriority,
    defaultSortPriority,
    defaultDirection,
}) => {
    const isSmallScreen = useMediaQuery({query: '(max-width: 1280px)'});

    const {items} = useBasket();

    // Проверяем, какие события произошли (доступность количества и обнуление)
    const itemsUnavailableOccurred = items.some((item) => item.quantityChangedToZero);
    const itemsCntExceedsQuantityOccurred = items.some((item) => item.quantityDecreased);

    const sortedItems = useSortedItems(sortOption, sortPriority, defaultSortPriority, defaultDirection);

    return (
        <div className={b()}>
            {sortedItems?.map((item) => {
                const dates = formatDate(item.createdAt, ['dd.mm.yy', 'HH:MM']);

                let errorText = '';

                if (itemsUnavailableOccurred && itemsCntExceedsQuantityOccurred) {
                    // Если сработали оба события, для всех товаров отображаем:
                    errorText = `К заказу было ${item.itemsCnt} шт. Осталось ${item.quantity} шт.`;
                } else if (itemsUnavailableOccurred && !itemsCntExceedsQuantityOccurred) {
                    // Если изменились только товары, где quantity стал равен 0
                    if (item.quantityChangedToZero) {
                        errorText = 'Товар закончился';
                    }
                } else if (!itemsUnavailableOccurred && itemsCntExceedsQuantityOccurred) {
                    // Если изменилось количество без обнуления
                    if (item.quantityDecreased) {
                        errorText = `К заказу было ${item.itemsCnt} шт. Осталось ${item.quantity} шт.`;
                    }
                }

                const quantityValue =
                    item.quantity > 0 ? Math.min(item.itemsCnt, item.quantity) : 0;

                // Вычисляем старую и новую суммы
                const oldAmount = (item.oldPrice ?? item.price) * quantityValue;
                const newAmount = item.oldPrice ? item.price * quantityValue : undefined;

                // Определяем цвет для отображения новой цены
                let priceColor: 'warning' | 'positive' | undefined;
                if (item.oldPrice) {
                    if (item.price > item.oldPrice) {
                        priceColor = 'warning'; // Цена увеличилась
                    } else if (item.price < item.oldPrice) {
                        priceColor = 'positive'; // Цена уменьшилась
                    }
                }

                return (
                    <div key={item.id} className={b('row')}>
                        <div className={b('item', b('is-selected'))}>
                            <Checkbox
                                size={'l'}
                                checked={item.checked}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    onItemCheckChange(item.id, event.target.checked)
                                }
                            ></Checkbox>
                        </div>

                        {!isSmallScreen && (
                            <div className={b('item', b('date'))}>
                                <Text variant={'body-2'}>{dates[0]}</Text>
                                <Text variant={'body-2'} className={b('date', 'time')}>
                                    {dates[1]}
                                </Text>
                            </div>
                        )}

                        <ArticleInfo
                            className={b('item', b('article'))}
                            header={{
                                article: item.article,
                                articleName: item.articleName,
                                brandName: item.brandName,
                            }}
                        />

                        <ProposalBadges
                            className={b('item', b('icons'))}
                            isOfficialDealer={item.proposal?.isOfficialDealer}
                            isReturnPossible={item.isReturnPossible}
                        />

                        <Rating value={item.rating} className={b('item', b('rating'))} />

                        {!isSmallScreen && (
                            <Quantity className={b('item', b('quantity'))} value={item.quantity} />
                        )}

                        <DeliveryDays
                            value={item.delivery.expected.client.days}
                            className={b('item', b('delivery-days'))}
                        />

                        <DeliveryDate
                            value={item.delivery.expected.client.date}
                            className={b('item', b('delivery-date'))}
                        />

                        <Price
                            value={item.oldPrice ?? item.price}
                            newValue={item.oldPrice ? item.price : undefined}
                            className={b('item', b('price'))}
                            color={priceColor}
                        />

                        <Price
                            value={oldAmount}
                            newValue={newAmount}
                            className={b('item', b('amount'))}
                            color={priceColor}
                        />

                        <QuantityInput
                            value={quantityValue}
                            multiplicity={item.multiplicity}
                            maxQuantity={item.quantity}
                            onChange={(newQuantity) => onItemQuantityChange(item.id, newQuantity)}
                            className={b('item', b('quantity-input'))}
                            errorText={errorText}
                        />

                        <AuxControls
                            item={item}
                            onDeleteItem={() => onDeleteItem(item.id)}
                        />
                    </div>
                );
            })}
        </div>
    );
};
