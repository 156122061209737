import React from 'react';
import block from 'bem-cn-lite';
import {Modal, Loader, Text} from '@gravity-ui/uikit';

import './CheckAvailableLoading.scss';

const b = block('check-available-modal');

interface CheckAvailableLoadingProps {
    open: boolean;
    onClose: () => void;
}

export const CheckAvailableLoading: React.FC<CheckAvailableLoadingProps> = ({open, onClose}) => {
    return (    
        <Modal open={open} onClose={onClose} className={b()}>
            <Text variant="subheader-3">Проверяем наличие и цены</Text>
            <Loader size="s" />
        </Modal>
    );
};
