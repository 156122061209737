import React, {useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import './QuantityInput.scss';
import {Button, TextInput, Text} from '@gravity-ui/uikit';
import {Minus, Plus} from '@gravity-ui/icons';

const b = block('quantity-input');

export interface QuantityInputProps {
    className?: string;
    value: number;
    multiplicity: number; // количество в корзине/заказе должно быть кратно этому параметру
    onChange: (newValue: number) => void;
    disabled?: boolean; // Новый пропс для отключения кнопок
    errorText?: string; // Новый пропс для показа красного текста
    maxQuantity: number; // Новый пропс для максимального количества itemsCnt
    onDecrease?: () => void; // Для поиска, чтобы делать активным минус и удалить по клику
}

export const QuantityInput: React.FC<QuantityInputProps> = ({
    className,
    value,
    multiplicity,
    onChange,
    disabled,
    errorText,
    maxQuantity,
    onDecrease
}) => {
    const [inputValue, setInputValue] = useState<string>(value.toString());

    const errorTextClass = errorText === 'Товар закончился'
        ? b('error-text-end')
        : b('error-text-start');

    useEffect(() => {
        setInputValue(value.toString());
    }, [value]);

    const handleIncrease = () => {
        if (value < maxQuantity) {
            const newQuantity = value + multiplicity;
            setInputValue(newQuantity.toString());
            onChange(newQuantity);
        }
    };

    const handleDecrease = () => {
        if (value > multiplicity) {
            const newQuantity = value - multiplicity;
            setInputValue(newQuantity.toString());
            onChange(newQuantity);
        } else if (value === multiplicity && onDecrease) {
            // Если количество multiplicity и передана функция onDecrease, вызываем её
            onDecrease();
        }
    };

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        if (input === '') {
            setInputValue('');
        } else {
            const newValue = parseInt(input, 10);
            if (!isNaN(newValue) && newValue <= maxQuantity) {
                setInputValue(e.target.value);
                onChange(newValue);
            }
        }
    };

    const handleBlur = () => {
        // Если поле оставлено пустым, возвращаем исходное значение
        if (inputValue === '') {
            setInputValue(value.toString());
        }
    };

    const isDecreaseDisabled = disabled || value <= 0 || (value === multiplicity && !onDecrease);

    return (
        <div className={b(null, className)}>
            <div className={b('container')}>
                <Button
                    disabled={isDecreaseDisabled}
                    size="m"
                    className={b('button')}
                    onClick={handleDecrease}
                >
                    <Minus />
                </Button>

                <TextInput
                    size="s"
                    view="clear"
                    className={b('input')}
                    value={inputValue}
                    onChange={handleChangeInput}
                    onBlur={handleBlur}
                ></TextInput>

                <Button
                    size="m"
                    className={b('button')}
                    onClick={handleIncrease}
                    disabled={disabled || value >= maxQuantity}
                >
                    <Plus />
                </Button>

                {errorText && (
                    <Text
                        className={errorTextClass}
                        variant="caption-1"
                        color="danger"
                    >
                        {errorText}
                    </Text>
                )}
            </div>
        </div>
    );
};
