import React, {useCallback, useState} from 'react';
import block from 'bem-cn-lite';
import './BasketHeaderControls.scss';
import {Checkbox, Text, Button} from '@gravity-ui/uikit';
import {ClockArrowRotateLeft, TrashBin} from '@gravity-ui/icons';
import {BasketTrashModal} from '../BasketTrashModal';
import {CheckAvailableLoading} from '../CheckAvailableLoading';
import {useToast} from '@hooks';
import { useBasket } from '@features/basket/hooks/useBasket';

const b = block('basket-header-controls');

export interface BasketHeaderControlsProps {
    allChecked: boolean;
    onSelectAll: (checked: boolean) => void;
    selectedCount: number;
    onDeleteSelected: () => void;
}

export const BasketHeaderControls: React.FC<BasketHeaderControlsProps> = ({
    allChecked,
    onSelectAll,
    selectedCount,
    onDeleteSelected,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(false);
    const {showToast} = useToast();
    const {refreshBasketItems} = useBasket();

    const handleDeleteClick = () => {
        setIsModalOpen(true);
    };

    const handleCheckClick = async () => {
        setIsCheckLoading(true);
        try {
            const {priceChanged, itemsUnavailable, itemsCntExceedsQuantity} =
                await refreshBasketItems();

            if (!priceChanged && !itemsUnavailable && !itemsCntExceedsQuantity) {
                showToast('success', 'Цены и наличие не изменились');
            } else if (itemsUnavailable && itemsCntExceedsQuantity) {
                showToast('danger', 'Количество доступных позиций по товарам в корзине изменилось');
            } else if (itemsUnavailable) {
                showToast('danger', 'Доступность части товаров в корзине изменилась');
            } else if (itemsCntExceedsQuantity && priceChanged) {
                showToast('warning', 'Цена и доступное количество товаров в корзине изменились');
            } else if (itemsCntExceedsQuantity) {
                showToast(
                    'warning',
                    'Количество доступных позиций по товарам в корзине изменилось',
                );
            } else if (priceChanged) {
                showToast('warning', 'Цены на часть товаров в корзине изменились');
            } 
        } catch (error) {
            showToast('danger', 'Произошла ошибка при проверке товаров');
        } finally {
            setIsCheckLoading(false);
        }
    };

    const handleConfirmDelete = useCallback(() => {
        onDeleteSelected();
        setIsModalOpen(false);
    }, [onDeleteSelected]);

    return (
        <div className={b()}>
            <Checkbox
                size={'l'}
                className={b('select-all')}
                checked={allChecked}
                onChange={(event) => onSelectAll(event.target.checked)}
            >
                <Text variant={'body-2'}>Выбрать всё</Text>
            </Checkbox>

            <Button
                view={'flat-danger'}
                disabled={selectedCount === 0}
                className={b('delete-selected')}
                onClick={handleDeleteClick}
            >
                <TrashBin />
                <Text variant={'body-2'}>Удалить выбранное</Text>
            </Button>

            <Button view={'flat-info'} className={b('check-items')} onClick={handleCheckClick}>
                <ClockArrowRotateLeft />
                <Text variant={'body-2'}>Проверить наличие и цены</Text>
            </Button>

            <BasketTrashModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirmDelete}
            />

            <CheckAvailableLoading open={isCheckLoading} onClose={() => setIsCheckLoading(false)} />
        </div>
    );
};
