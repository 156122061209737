import React, {useState} from 'react';
import block from 'bem-cn-lite';
import './BasketItems.scss';
import {Header, Items} from './components';
import {BasketItemModel} from '@features/basket/interfaces';
import {defaultDirection, defaultSortPriority} from '@features/basket/utils/sortUtils';

const b = block('basket-items');

export interface BasketItemsProps {
    items?: BasketItemModel[];
    onItemQuantityChange: (id: string, newQuantity: number) => void;
    onItemCheckChange: (id: string, checked: boolean) => void;
    onDeleteItem: (id: string) => void;
}

export const BasketItems: React.FC<BasketItemsProps> = ({
    items,
    onItemQuantityChange,
    onItemCheckChange,
    onDeleteItem
}) => {
    const [sortPriority, setSortPriority] = useState<string[]>(defaultSortPriority);
    // Состояние для выбранного поля сортировки и направления
    const [sortOption, setSortOption] = useState<{property: string; direction: 'asc' | 'desc'} | null>(null);

    const handleSortChange = (field: string) => {
        setSortOption((prevSortOption) => {
            if (prevSortOption && prevSortOption.property === field) {
                return {
                    property: field,
                    direction: prevSortOption.direction === 'asc' ? 'desc' : 'asc',
                };
            } else {
                const direction = defaultDirection.get(field) === 1 ? 'asc' : 'desc';
                return {
                    property: field,
                    direction: direction as 'asc' | 'desc',
                };
            }
        });

        setSortPriority((prevSortPriority) => {
            const newSortPriority = prevSortPriority.filter(item => item !== field);
            newSortPriority.unshift(field);
            return newSortPriority;
        });
    };

    return (
        <div className={b()}>
            <Header
                sortOption={sortOption}
                onSortChange={handleSortChange}
            />
            <Items
                sortOption={sortOption}
                sortPriority={sortPriority}
                defaultSortPriority={defaultSortPriority}
                defaultDirection={defaultDirection}
                onItemQuantityChange={onItemQuantityChange}
                onItemCheckChange={onItemCheckChange}
                onDeleteItem={onDeleteItem}
            />
        </div>
    );
};
