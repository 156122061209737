import { createContext } from 'react';

interface ToastVisibilityContextProps {
    isToasterVisible: boolean;
    setIsToasterVisible: (visible: boolean) => void;
    currentToastType: string | null;
    setCurrentToastType: (type: string | null) => void;
}

export const ToastVisibilityContext = createContext<ToastVisibilityContextProps>({
    isToasterVisible: false,
    setIsToasterVisible: () => {},
    currentToastType: null,
    setCurrentToastType: () => {},
});