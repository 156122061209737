import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Button} from '@gravity-ui/uikit';
import {Magnifier, TrashBin} from '@gravity-ui/icons';
import {BasketTrashModal} from '@features/basket/components/BasketTrashModal';
import {TooltipButton} from './TooltipButton/TooltipButton';
import { BasketItemModel } from '@features/basket/interfaces';
import { CommentPopoverButton } from './CommentPopoverButton/CommentPopoverButton';

import './AuxControls.scss';

const b = block('aux-controls');

export interface AuxControlsProps {
    className?: string;
    onDeleteItem: () => void;
    item: BasketItemModel;
}

export const AuxControls: React.FC<AuxControlsProps> = ({className, onDeleteItem, item}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    /*Изменение цвета иконки при обновлении*/
    let view: 'outlined' | 'outlined-warning' = 'outlined';

    // Проверяем, увеличилась ли цена
    if (item.oldPrice && item.price > item.oldPrice) {
        view = 'outlined-warning';
    }
    // Проверяем, изменилось ли количество
    else if (item.quantityChangedToZero || item.quantityDecreased) {
        view = 'outlined-warning';
    }
    /*Изменение цвета иконки при обновлении*/

    const handleDeleteClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        onDeleteItem();
        setIsModalOpen(false);
    };

    const handleSearchClick = () => {
        const url = `/search?text=${item.article}&brand=${item.brandName}&useAnalogs=false`;
        window.open(url, '_blank');
    }

    return (
        <div className={b(null, className)}>
            <CommentPopoverButton item={item} />

            <TooltipButton
                tooltipContent={
                    <>
                        Поиск замены. <br />
                        Откроется в новом окне
                    </>
                }
                icon={<Magnifier />}
                className={b('button', b('search'))}
                view={view}
                onClick={handleSearchClick}
            />

            <Button
                view={'outlined'}
                className={b('button', b('delete'))}
                onClick={handleDeleteClick}
            >
                <TrashBin />
            </Button>

            <BasketTrashModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};
