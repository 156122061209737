import {IUser, userFromToken, userStorage} from './user';
import React from 'react';
import {tokenStorage} from './token';
import {EventName} from './events';
import {countersStorage} from './counters';
import {BasketItemModel} from '@features/basket/interfaces';
import {BasketSummaryModel} from '@features/basket';
import {basketStorage} from './basketStorage';

export interface Context {
    state: AppState;
    emit: (name: EventName, event?: object) => void;
}

export interface AppState {
    user?: IUser;
    token?: string;
    counters: Record<string, number>;
    isBrandSearchActive: boolean; // поле для добавления в хлебные крошки "Уточнение запроса" в хедере
    basket: {
        items: BasketItemModel[];
        itemsOut: BasketItemModel[];
        summary: BasketSummaryModel;
        allChecked: boolean;
        allCheckedItemsOut: boolean;
        selectedCount: number;
    };
}

export const AppContext = React.createContext<Context>({
    state: {
        counters: {},
        isBrandSearchActive: false,
        basket: {
            items: [],
            itemsOut: [],
            summary: {
                totalAmount: 0,
                totalQuantity: 0
            },
            allChecked: true,
            allCheckedItemsOut: true,
            selectedCount: 0
        }
    },
    emit: (_) => {}
});

export const loadStateFromLS = (): AppState => {
    const token = tokenStorage.get();

    const storedBasket = basketStorage.get();
    const initialBasketState: AppState['basket'] = {
        items:
            storedBasket?.items?.map((item) => ({
                ...item,
                checked: item.checked ?? true,
            })) || [],
        itemsOut: storedBasket?.itemsOut || [],
        summary: storedBasket?.summary || {
            totalAmount: 0,
            totalQuantity: 0
        },
        allChecked: storedBasket?.allChecked ?? true,
        allCheckedItemsOut: storedBasket?.allCheckedItemsOut ?? true,
        selectedCount: storedBasket?.selectedCount ?? 0
    };

    // Загрузка счетчиков из localStorage
    let initialCounters = countersStorage.get();

    initialCounters['basketItems'] = initialBasketState.items.length;

    countersStorage.set(initialCounters);

    if (!token) {
        userStorage.clear();

        return {
            counters: countersStorage.get() || initialCounters,
            isBrandSearchActive: false,
            basket: basketStorage.get() || initialBasketState
        };
    }

    return {
        token: token,
        user: userFromToken(token),
        counters: countersStorage.get() || initialCounters,
        basket: basketStorage.get() || initialBasketState,
        isBrandSearchActive: false
    };
};
