import React, {ChangeEvent} from 'react';
import { useMediaQuery } from 'react-responsive';
import block from 'bem-cn-lite';
import { BasketItemModel } from '@features/basket/interfaces';
import {
    ArticleInfo,
    DeliveryDate,
    DeliveryDays,
    Price,
    ProposalBadges,
    Quantity,
    QuantityInput,
    Rating,
} from '@components';
import {Checkbox, Text} from '@gravity-ui/uikit';
import {formatDate} from '@utils';
import { AuxControls } from './components';
import { useBasket } from '@features/basket/hooks/useBasket';


const b = block('items');

interface ItemsProps {
    items?: BasketItemModel[];
    itemsUnavailableOccurred: boolean;
    itemsCntExceedsQuantityOccurred: boolean;
    onItemCheckChange: (id: string, checked: boolean) => void;
    onDeleteItem: (id: string) => void;
}

export const Items: React.FC<ItemsProps> = ({itemsUnavailableOccurred, itemsCntExceedsQuantityOccurred, onItemCheckChange, onDeleteItem}) => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1280px)' });

    const { itemsOut } = useBasket();

    // Маппинг данных, чтобы преобразовать значение returnCondition в boolean
    const mappedItems = itemsOut?.map((item) => ({
        ...item,
        isReturnPossible: item.returnCondition !== 'impossible',
    }));

    return (
        <div className={b()}>
            {mappedItems?.map((item) => {
                const dates = formatDate(item.createdAt, ['dd.mm.yy', 'HH:MM']);
             
                let errorText = '';

                if (itemsUnavailableOccurred && itemsCntExceedsQuantityOccurred) {
                    errorText = `К заказу было ${item.itemsCnt} шт. Осталось ${item.quantity} шт.`;
                } else if (itemsUnavailableOccurred && !itemsCntExceedsQuantityOccurred) {
                    if (item.quantityChangedToZero) {
                        errorText = 'Товар закончился';
                    }
                } else if (!itemsUnavailableOccurred && itemsCntExceedsQuantityOccurred) {
                    if (item.quantityDecreased) {
                        errorText = `К заказу было ${item.itemsCnt} шт. Осталось ${item.quantity} шт.`;
                    }
                }

                const quantityValue = item.quantity > 0 ? Math.min(item.itemsCnt, item.quantity) : 0;

                return (
                    <div key={item.id} className={b('row')}>
                        <div className={b('item', b('is-selected'))}>
                            <Checkbox
                                size={'l'}
                                checked={item.checked}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    onItemCheckChange(item.id, event.target.checked)
                                }
                            ></Checkbox>
                        </div>

                        {!isSmallScreen && (
                            <div className={b('item', b('date'))}>
                                <Text variant={'body-2'}>{dates[0]}</Text>
                                <Text variant={'body-2'} className={b('date', 'time')}>
                                    {dates[1]}
                                </Text>
                            </div>
                        )}

                        <ArticleInfo
                            className={b('item', b('article'))}
                            header={{
                                article: item.article,
                                articleName: item.articleName,
                                brandName: item.brandName,
                            }}
                        />

                        <ProposalBadges
                            className={b('item', b('icons'))}
                            isOfficialDealer={item.proposal?.isOfficialDealer}
                            isReturnPossible={item.isReturnPossible}
                        />

                        <Rating value={item.rating} className={b('item', b('rating'))} />

                        {!isSmallScreen && (
                            <Quantity
                                className={b('item', b('quantity'))}
                                value={item.quantity}
                            />
                        )}

                        <DeliveryDays
                            value={item.delivery.expected.client.days}
                            className={b('item', b('delivery-days'))}
                        />

                        <DeliveryDate
                            value={item.delivery.expected.client.date}
                            className={b('item', b('delivery-date'))}
                        />

                        <Price value={item.price} newValue={item.newPrice} className={b('item', b('price'))} />

                        <Price value={item.amount ?? 0} className={b('item', b('amount'))} />

                        <QuantityInput
                            value={quantityValue}
                            multiplicity={item.multiplicity}
                            maxQuantity={item.quantity}
                            onChange={() => {}}
                            className={b('item', b('quantity-input'))}
                            errorText={errorText}
                        />

                        <AuxControls
                            item={item}
                            onDeleteItem={() => onDeleteItem(item.id)} 
                        />
                    </div>
                );
            })}
        </div>
    );
};
